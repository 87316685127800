import { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import {
  MainContainer,
  StatContainer,
  StatsContainer,
  StatsContainer2,
} from "./styled";
import { Description, SectionHeader } from "components/Common/styled";
import {
  useFeesAndUsers,
  useHourlyVolume,
  useTotalVolume,
  useKitsuneStats,
} from "api/TradingInfo";
import { formatAmount, numberWithCommas } from "api/formatAmount";

const Stats = function () {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const volume_24h = useHourlyVolume();
  const totalVolume = useTotalVolume();
  const feesAndUsers = useFeesAndUsers();
  const kitsuneStats = useKitsuneStats();

  const [currentVolumeInfo_24h, setCurrentVolumeInfo_24h] = useState(null);
  const [currentVolumeInfo, setCurrentVolumeInfo] = useState(null);
  const [totalFees, setTotalFees] = useState(null);

  useEffect(() => {
    if (volume_24h?.data?.volume) {
      let kitVol24 = 0;
      if (kitsuneStats?.data?.daily?.volume) {
        // console.log('adding kitsune (24)', kitsuneStats.data.daily.volume)
        kitVol24 = kitsuneStats.data.daily.volume;
      }
      const volumeInfo_24h = formatAmount(volume_24h.data.volume, 30, 0, false);

      setCurrentVolumeInfo_24h(
        numberWithCommas(Number(volumeInfo_24h) + Number(kitVol24.toFixed()))
      );
    }
  }, [volume_24h.data.volume, kitsuneStats.data.daily.volume]);

  useEffect(() => {
    if (totalVolume?.data?.volume) {
      let kitVolAll = 0;
      if (kitsuneStats?.data?.allTime?.volume) {
        // console.log('adding kitsune (alltime)', kitsuneStats.data.allTime.volume)
        kitVolAll = kitsuneStats.data.allTime.volume;
      }
      const volumeInfo = formatAmount(totalVolume.data.volume, 30, 0, false);
      setCurrentVolumeInfo(
        numberWithCommas(Number(volumeInfo) + Number(kitVolAll.toFixed()))
      );
    }
  }, [totalVolume.data, kitsuneStats?.data?.allTime?.volume]);

  useEffect(() => {
    if (feesAndUsers.data?.fees) {
      const mintFees = formatAmount(feesAndUsers.data.fees.mint, 30, 0, true);
      const tradingFees = formatAmount(
        feesAndUsers.data.fees.margin,
        30,
        0,
        true
      );

      const total =
        parseFloat(mintFees.replace(",", "")) +
        parseFloat(tradingFees.replace(",", ""));
      setTotalFees(total.toLocaleString("en-US"));
    }
  }, [
    volume_24h.data.volume,
    totalVolume.data.volume,
    feesAndUsers.data?.fees,
  ]);

  if (isSmallScreen)
    return (
      <MainContainer>
        <StatsContainer>
          <StatContainer>
            <Description>Total Trading Volume</Description>
            <SectionHeader>${currentVolumeInfo || 0}</SectionHeader>
          </StatContainer>
          <StatContainer>
            <Description>Todays Trading Volume</Description>
            <SectionHeader>${currentVolumeInfo_24h || 0}</SectionHeader>
          </StatContainer>
        </StatsContainer>
        <StatsContainer2>
          <StatContainer>
            <Description>Total Fees</Description>
            <SectionHeader>${totalFees || 0}</SectionHeader>
          </StatContainer>
          <StatContainer>
            <Description>Total Users</Description>
            <SectionHeader>
              {feesAndUsers.data?.users.uniqueCountCumulative || 0}
            </SectionHeader>
          </StatContainer>
        </StatsContainer2>
      </MainContainer>
    );

  return (
    <MainContainer>
      <StatsContainer>
        <StatContainer>
          <Description>Total Trading Volume</Description>
          <SectionHeader>${currentVolumeInfo || 0}</SectionHeader>
        </StatContainer>
        <StatContainer>
          <Description>Todays Trading Volume</Description>
          <SectionHeader>${currentVolumeInfo_24h || 0}</SectionHeader>
        </StatContainer>
        <StatContainer>
          <Description>Total Fees</Description>
          <SectionHeader>${totalFees || 0}</SectionHeader>
        </StatContainer>
        <StatContainer>
          <Description>Total Users</Description>
          <SectionHeader>
            {feesAndUsers.data?.users.uniqueCountCumulative || 0}
          </SectionHeader>
        </StatContainer>
      </StatsContainer>
    </MainContainer>
  );
};

export default Stats;
